import {useRef, useState} from 'react'

export const FileAttachments = (props: any) => (
  <div
    style={{
      backgroundColor: '#f5f5f5',
      border: '1px solid transparent',
      fontWeight: 'bold',
      margin: '5px 5px 9px',
      overflowY: 'hidden',
      padding: '4px 4px 4px 8px',
      maxWidth: '248px',
      cursor: 'pointer',
    }}
    key={props.keyValue || Math.random()}
  >
    <div
      style={{
        display: 'inline-block',
        overflow: 'hidden',
        padding: '3px 0',
        textOverflow: 'ellipsis',
        verticalAlign: 'bottom',
        whiteSpace: 'nowrap',
        maxWidth: '315px',
        color: '#15c',
      }}
    >
      <i className='fas fa-paperclip me-2'></i>
      <span
        onClick={() => {
          if (props.showPreview) props.showPreview()
        }}
      >
        {' '}
        {props.fileName}
      </span>

      <i
        className='fas fa-trash ms-2'
        onClick={() => {
          if (props.deleteFile) props.deleteFile(props.keyValue)
        }}
      ></i>
    </div>
  </div>
)

function ImageUpload(props: any) {
  const [selectedImage, setSelectedImage] = useState(null)
  const fileInputRef = useRef<any>(null)

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    if (props.addFiles)
      props.addFiles({
        title: file.name,
        file: file,
      })
    setSelectedImage(file)
    console.log('Image selected:', file)
  }

  const handleButtonClick = () => {
    if (fileInputRef && fileInputRef.current && fileInputRef.current) fileInputRef.current?.click()
  }

  return (
    <div style={{padding: '10px 0 0 0'}}>
      <input
        type='file'
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{display: 'none'}}
      />
      <button type='button' onClick={handleButtonClick}>
        upload
      </button>
    </div>
  )
}

export default ImageUpload
