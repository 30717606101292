import React, {FC, useEffect, useState} from 'react'
import {CustomAlert, IAlert} from '../../../../../app/shared/components/CustomAlert'
import {Editor1} from '../../../widgets/editor/Editor1'
import EmailInput from '../../../widgets/input/EmailInput1'
import {Window, DialogActionsBar} from '@progress/kendo-react-dialogs'
import ImageUpload, {FileAttachments} from '../Upload'
import {IEmailSend} from '../../../../../app/shared/model/smtp-register'
import {emailSendDefault} from '../defaults/email-send-default'
import {downloadBlob} from '../../../../../app/shared/service/utils'

interface IProps {
  onClose: () => void
  onSend: (emailSend: IEmailSend) => void
  errorMsg: string
  dataEmailSend: IEmailSend
  changeEmailSend: (emailSend: IEmailSend) => void
  disabled: boolean
  proceedMsg: string
}

const SendQuoteModal: FC<IProps> = ({
  onClose,
  onSend,
  dataEmailSend,
  changeEmailSend,
  errorMsg,
  disabled,
  proceedMsg,
}) => {
  const [customAlert, setAlert] = useState<IAlert | undefined>(undefined)
  const [emailSend, setEmailSend] = useState<IEmailSend>(dataEmailSend || emailSendDefault)

  useEffect(() => {
    if (dataEmailSend) {
      setEmailSend(dataEmailSend)
    }
  }, [dataEmailSend])

  useEffect(() => {
    if (emailSend) {
      changeEmailSend(emailSend)
    }
  }, [emailSend])

  useEffect(() => {
    if (errorMsg) {
      setAlert({
        message: `Please Review/Click Attachment before we proceed `,
        header: `Preview the Confirm Job.`,
        type: 'danger',
      })
    } else setAlert(undefined)
  }, [errorMsg])

  return (
    <Window title={'Email Quote'} onClose={onClose} initialHeight={720} initialWidth={900}>
      {customAlert && <CustomAlert {...customAlert} />}

      <div className='row'>
        <div className='col-1'>To:</div>
        <div className='col-11'>
          <EmailInput
            emails={emailSend.emails}
            onAddDeleteEmail={(newEmails) => {
              setEmailSend({
                ...emailSend,
                emails: newEmails,
              })
            }}
          ></EmailInput>
        </div>
      </div>
      <div className='row'>
        <div className='col-1'>Cc:</div>
        <div className='col-11'>
          <EmailInput
            emails={emailSend.emailCcs}
            onAddDeleteEmail={(newCcs) => {
              setEmailSend({
                ...emailSend,
                emailCcs: newCcs,
              })
            }}
          ></EmailInput>
        </div>
      </div>
      <div className='row'>
        <div className='col-1'>Bcc:</div>
        <div className='col-11'>
          <EmailInput
            emails={emailSend.emailBccs}
            onAddDeleteEmail={(newBccs) => {
              setEmailSend({
                ...emailSend,
                emailBccs: newBccs,
              })
            }}
          ></EmailInput>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-1'>Subject:</div>
        <div className='col-11'>
          <input
            type='text'
            className='form-control'
            value={emailSend.subjects}
            onChange={(event) => {
              setEmailSend({
                ...emailSend,
                subjects: event.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-12'>
          <div className='d-flex flex-row'>
            {emailSend.attachments.map((att, i) => (
              <FileAttachments
                showPreview={() => downloadBlob(att.file, att.title)}
                fileName={att.title}
                keyValue={`attch-${i}`}
                deleteFile={(i: number) => {
                  emailSend.attachments.splice(i, 1)
                  setEmailSend({
                    ...emailSend,
                    attachments: [...emailSend.attachments],
                  })
                }}
              />
            ))}

            <ImageUpload
              addFiles={(newfile: any) => {
                setEmailSend({
                  ...emailSend,
                  attachments: [...emailSend.attachments, newfile],
                })
              }}
            ></ImageUpload>
          </div>
        </div>
      </div>
      <Editor1
        content={emailSend.currentContent}
        changeHandler={(event) => {
          setEmailSend({
            ...emailSend,
            currentContent: event,
          })
        }}
      ></Editor1>
      <DialogActionsBar>
        <button
          type='button'
          className='btn btn-primary'
          disabled={disabled}
          onClick={() => {
            if (emailSend.emails.length === 0) {
              setAlert({
                message: `Please add atleast one email address.`,
                header: `Preview the Purchase Order.`,
                type: 'danger',
              })
              return
            }
            onSend(emailSend)
          }}
        >
          {proceedMsg}
        </button>
        <button className='btn btn-danger' onClick={onClose}>
          Cancel
        </button>
      </DialogActionsBar>
    </Window>
  )
}

export {SendQuoteModal}
