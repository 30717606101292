
import { useHistory } from "react-router-dom";
import { usePageData } from "./PageData"
import React, { FC, useEffect, useState } from "react";

const PageControlProvider: FC<{ allowedAccess: string[], isRedirect?: boolean }> = ({ children, allowedAccess, isRedirect }) => {
    const { currentUser } = usePageData()
    const history = useHistory();
    const [isEnabled, setIsEnabled] = useState<boolean>(false)

    useEffect(() => {
      if (currentUser && currentUser.scopes.length > 0) {
        const hasAccess = allowedAccess.some(scope => currentUser.scopes.includes(scope));
        setIsEnabled(hasAccess);

        if (isRedirect && !hasAccess) {
          history.push('/error/404');
        }
      }
      
    }, [currentUser]);

    return isEnabled ? <>{children}</> : null;
};

export {PageControlProvider}
