/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {usePageData} from '../../core'
// import {KTSVG} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import { PageControlProvider } from '../../core/PageControlProvider'
import { MODULE_SCOPE } from '../../../../app/shared/service/user-role-utils'

export function AsideMenuMain() {
  const {currentUser} = usePageData()
  const [admin, setAdmin] = useState<boolean>(false)
  useEffect(() => {
    var userRoles: any = currentUser.userRoles
    userRoles.forEach((element: any) => {
      if (element.toLowerCase() === 'administrator') {
        setAdmin(true)
      }
    })
  }, [currentUser])
  const intl = useIntl()
  const {userAccessModule} = usePageData()
  return (
    <React.Fragment>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='fa-th'
      />
      <PageControlProvider allowedAccess={[MODULE_SCOPE.QUOTES]}>
        <AsideMenuItem
            to='/quote'
            icon='/media/icons/duotune/art/art002.svg'
            title={intl.formatMessage({id: 'MENU.QUOTES'})}
            fontIcon='fa-clipboard-list'
          />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.JOBS]}>
        <AsideMenuItem
            to='/job'
            icon='/media/icons/duotune/art/art002.svg'
            title={intl.formatMessage({id: 'MENU.JOBS'})}
            fontIcon='fa-tasks'
          />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.PURCHASEORDERS]}>
        <AsideMenuItem
            to='/purchase-order'
            icon='/media/icons/duotune/art/art002.svg'
            title={intl.formatMessage({id: 'MENU.PURCHASEORDERS'})}
            fontIcon='fa-file-invoice-dollar'
          />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.DELIVERIES]}>
        <AsideMenuItem
          to='/delivery'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.DELIVERIES'})}
          fontIcon='fa-truck'
        />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.STOCK]}>
        <AsideMenuItem
          to='/stock'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.STOCKS'})}
          fontIcon='fa-pallet'
        />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.CLIENTS]}>
        <AsideMenuItem
          to='/client'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.CLIENTS'})}
          fontIcon='fa-users'
        />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.SCHEDULES]}>
        <AsideMenuItem
          to='/schedule'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.SCHEDULES'})}
          fontIcon='fa-calendar-alt'
        />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.SUPPLIERS]}>
        <AsideMenuItem
          to='/supplier'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.SUPPLIERS'})}
          fontIcon='fa-store'
        />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.NONCONFORMANCE]}>
        <AsideMenuItem
            to='/non-conformance'
            icon='/media/icons/duotune/art/art002.svg'
            title={intl.formatMessage({id: 'MENU.NONCONFORMANCE'})}
            fontIcon='fa-assistive-listening-systems'
          />
      </PageControlProvider>
      <PageControlProvider allowedAccess={[MODULE_SCOPE.SETTINGS]}>
            <AsideMenuItemWithSub
              to='#'
              icon='/media/icons/duotune/art/art002.svg'
              title={intl.formatMessage({id: 'MENU.SETTINGS'})}
              fontIcon='fa-cog'
            >
            
                  <AsideMenuItem
                    to='/resource/category/list'
                    title='Resource Categories'
                    hasBullet={true}
                  />
              <AsideMenuItem to='/resource/list' title='Resources' hasBullet={true} />
             <AsideMenuItem to='/user' title='Users' hasBullet={true} />
             <AsideMenuItem to='/role' title='Role' hasBullet={true} />
             <AsideMenuItem to='/change-password' title='Change Password' hasBullet={true} />
          </AsideMenuItemWithSub>
      </PageControlProvider>

    </React.Fragment>
  )

  // return (
  //   <>
  //     <AsideMenuItem
  //       to='/dashboard'
  //       icon='/media/icons/duotune/art/art002.svg'
  //       title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
  //       fontIcon='bi-app-indicator'
  //     />
  //     <AsideMenuItem
  //       to='/builder'
  //       icon='/media/icons/duotune/general/gen019.svg'
  //       title='Layout Builder'
  //       fontIcon='bi-layers'
  //     />
  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
  //       </div>
  //     </div>
  //     <AsideMenuItemWithSub
  //       to='/crafted/pages'
  //       title='Pages'
  //       fontIcon='bi-archive'
  //       icon='/media/icons/duotune/general/gen022.svg'
  //     >
  //       <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
  //         <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
  //         <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
  //         <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
  //         <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
  //         <AsideMenuItem
  //           to='/crafted/pages/profile/connections'
  //           title='Connections'
  //           hasBullet={true}
  //         />
  //       </AsideMenuItemWithSub>

  //       <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
  //         <AsideMenuItem
  //           to='/crafted/pages/wizards/horizontal'
  //           title='Horizontal'
  //           hasBullet={true}
  //         />
  //         <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
  //       </AsideMenuItemWithSub>
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub
  //       to='/crafted/accounts'
  //       title='Accounts'
  //       icon='/media/icons/duotune/communication/com006.svg'
  //       fontIcon='bi-person'
  //     >
  //       <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
  //       <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub
  //       to='/error'
  //       title='Errors'
  //       fontIcon='bi-sticky'
  //       icon='/media/icons/duotune/general/gen040.svg'
  //     >
  //       <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
  //       <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
  //     </AsideMenuItemWithSub>
  //     <AsideMenuItemWithSub
  //       to='/crafted/widgets'
  //       title='Widgets'
  //       icon='/media/icons/duotune/general/gen025.svg'
  //       fontIcon='bi-layers'
  //     >
  //       <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
  //       <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
  //       <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
  //       <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
  //       <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
  //       <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
  //     </AsideMenuItemWithSub>
  //     <div className='menu-item'>
  //       <div className='menu-content pt-8 pb-2'>
  //         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
  //       </div>
  //     </div>
  //     <AsideMenuItemWithSub
  //       to='/apps/chat'
  //       title='Chat'
  //       fontIcon='bi-chat-left'
  //       icon='/media/icons/duotune/communication/com012.svg'
  //     >
  //       <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
  //       <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
  //       <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
  //     </AsideMenuItemWithSub>
  //     <div className='menu-item'>
  //       <div className='menu-content'>
  //         <div className='separator mx-1 my-4'></div>
  //       </div>
  //     </div>
  //     <div className='menu-item'>
  //       <a
  //         target='_blank'
  //         className='menu-link'
  //         href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
  //       >
  //         <span className='menu-icon'>
  //           <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
  //         </span>
  //         <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
  //       </a>
  //     </div>
  //   </>
  // )
}
