import React, {useEffect, useState} from 'react'
import {QuoteDetail} from '../../models/quote-detail'
import {Modal1} from '../../../../../_metronic/partials/modals/Modal1'
import {
  ActionEnum,
  ActionOption,
} from '../../../../../_metronic/partials/widgets/grid/constant/ActionOption'
import {QUOTE_DETAIL_DEFAULT} from '../../constant/quote-default'
import {CellRender, RowRender} from '../../../../shared/components/kendo/renderer'
import {CurrencyFormatter, onKeyDownHandler} from '../../../../shared/service/utils'
import {
  FIELD_QUOTES_COLUMN_KEY,
  FIELD_QUOTE_MAT_COLUMN_KEY,
  FIELD_QUOTE_MAT_LIST,
  FIELD_QUOTE_TABINDEX,
} from '../../constant/config-map'
import {GRID_WIDTH, saveResizeColumn} from '../../../../shared/service/grid-setup-utils'
import { SortDescriptor, orderBy } from '@progress/kendo-data-query'
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridColumnResizeEvent,
} from '@progress/kendo-react-grid'
import {AddButtonHeader} from '../../../../../_metronic/partials/content/button/kendo/AddButtonHeader'
import {InCellTextCell} from '../../../../shared/components/kendo/incell/InCellTextCell'
import {InlineCellAmountCell} from '../../../../shared/components/kendo/incell/InCellAmountCell'
import {InCellNumericCell} from '../../../../shared/components/kendo/incell/InCellNumericCell'
import {GridActionIconCell} from '../../../../../_metronic/partials/widgets/grid/action/GridActionIconCell'
import { Control, useFieldArray } from 'react-hook-form'
import { INewEditData, INewQuote } from '../../models/new-qoute-model'
import { useOutsideClick } from '../../../utility/outside-click'

const EDIT_FIELD = 'inEdit'
const initialSort: Array<SortDescriptor> = [{field: 'sortIdx', dir: 'asc'}]
let copyDetails: any = [];
interface IProps {
  // details: QuoteDetail[]
  refresh: number
  isCanEdit: boolean
  // addDetail: (material: QuoteDetail) => void
  // updateDetail: (idx: number, material: QuoteDetail) => void
  // updateDetails: (materials: QuoteDetail[]) => void
  // removeDetail: (idx: number) => void
  // replaceDetails: (material: QuoteDetail[]) => void
  control: Control<INewEditData>
}

const QuoteDetails: React.FC<IProps> = ({
  // details,
  refresh,
  isCanEdit,
  // addDetail,
  // updateDetail,
  // updateDetails,
  // removeDetail,
  // replaceDetails,
  control
}) => {

  const {
    fields: details,
    insert: addDetails,
    remove: removeDetails,
    update: updateDetails,
    replace: replaceDetails,
  } = useFieldArray({
    control,
    name: 'details',
    keyName: 'keyIdx',
  })

  const [sort, setSort] = React.useState(initialSort)
  const [currentSortIdx, setSortIdx] = useState<number>(details.length);
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const footerCellStyle = {
    padding: '5px 5px', // Adjust the padding value as needed
    fontWeight: 'bold', // Apply any other styling you want
  }

  // **
  // Event Handler
  // **
  const [selectedDetail, setSelectedDetail] = useState<QuoteDetail>(QUOTE_DETAIL_DEFAULT)
  const [isShowDeletedModal, setIsShowDeletedModal] = useState<boolean>(false)
  const actionHandler = (event: any, dataItem: any, dataIndex: number) => {
    setSelectedIndex(dataIndex)
    setSelectedDetail(dataItem)
    switch (event) {
      case ActionEnum.Delete:
        setIsShowDeletedModal(true)
        break
    }
  }

  useEffect(() => {
    refreshSort();
  }, [refresh])

  useEffect(() => {
    copyDetails = details;
  }, [details])

  const proceedHandler = () => {
    setIsShowDeletedModal(false)
    if (selectedDetail) {
      if (selectedDetail.id && selectedDetail.id > 0) {
        var index = (details || []).findIndex((x) => x.id === selectedDetail.id)
        updateDetails(index, {...selectedDetail, isDeleted: true})
      } else {
        removeDetails(selectedIndex)
      }
    }
  }

  const onKeyDown = (event: any) => {

    const {nextField, dataIndex} = onKeyDownHandler(event, FIELD_QUOTES_COLUMN_KEY)

    details.forEach((item: any, index) => {
      updateDetails(index, {
        ...item,
        [event.field]: event.dataIndex === index ? event.value : item[event.field],
        [EDIT_FIELD]: dataIndex === index ? nextField : undefined,
        isEdit: dataIndex === index,
      })
    })
    setIsTabKey(true)

    if (dataIndex >= details.length) {
      focusInput()
      // Tab out from Grid
      setTimeout(() => {
        sortDetails(copyDetails)
      }, 100);
    }
  }

  const focusInput = async () => {
    if (document && document.activeElement) {
    // Focus on the specific input
    document.getElementById('quoteGrid')?.focus()
    }
  };

  const [autoColumns, setAutoColumns] = useState<any[]>(FIELD_QUOTE_MAT_COLUMN_KEY)
  const handleResizeColumn = (props: GridColumnResizeEvent) => {
    saveResizeColumn(props, autoColumns, GRID_WIDTH.GRID_QUOTES_MAT_LIST)
  }

  const addNewMaterial = () => {
    const currSortIdx = currentSortIdx + 1;

    setSortIdx(currSortIdx);
    setSelectedIndex(0)
    addDetails(0, {...QUOTE_DETAIL_DEFAULT, sortIdx: currSortIdx})
  }

  // **
  // Grid Renderer
  // **
  const [isTabKey, setIsTabKey] = useState<boolean>(false)
  const itemChange = (event: any) => {
    if (isTabKey) {
      setIsTabKey(true)
      return
    }
    let field = event.field || ''
    event.dataItem[field] = event.value

    if (field === 'quantity' || field === 'costPerUnit') {
      const {costPerUnit, quantity} = event.dataItem
      event.dataItem = {
        ...event.dataItem,
        totalCost: quantity === 0 ? 0 : Number((quantity * costPerUnit).toFixed(2)),
      }
    }
    if (field === 'totalCost') {
      let {quantity} = event.dataItem
      event.dataItem = {
        ...event.dataItem,
        costPerUnit: quantity === 0 ? 0 : Number((event.value / quantity).toFixed(2)),
      }
    }

    const idx = details.findIndex((po) => po.isEdit)
    details[idx] = event.dataItem
    updateDetails(idx, {...event.dataItem})
  }

  const enterEdit = (_: any, field: any, dataIndex: any) => {
    if (!isCanEdit) return
    const data = details
    // const callData: any = []
    data.forEach((item, index) => {
      var newData = {
        ...item,
        [EDIT_FIELD]: dataIndex === index ? field : undefined,
        isEdit: dataIndex === index,
      }
      // callData.push(newData)
      updateDetails(index, newData)
    })
    // updateDetails(callData)
  }
  const exitEdit = () => {
    if (isTabKey) {
      setIsTabKey(false)
      return
    }
    /* REMOVE isEdit: false on blur
    const data = qouteDetails
    // const callData: any = []
    /* data.forEach((item, index) => {
      var newData = {
        ...item,
        [EDIT_FIELD]: undefined,
        isEdit: false,
      }
      // callData.push(newData)
      updateDetails(index, {
        ...newData
      })
    })*/
    // updateDetails(callData)
    setTimeout(() => {
      if(!copyDetails.find((item: any) => item.isEdit)) {
        sortDetails(copyDetails);
      }
    }, 100);
  }

  const customCellRender = (td: any, props: any) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={EDIT_FIELD}
      exitEdit={exitEdit}
      onKeyDown={onKeyDown}
    />
  )

  const customRowRender = (tr: any, props: any) => (
    <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />
  )

  const addButtonHeader = (props: any) => {
    return (
      <AddButtonHeader
        title={props.title}
        clickHandler={addNewMaterial}
        isCanEdit={isCanEdit}
        tabIndex={FIELD_QUOTE_TABINDEX.btnAddQuote}
        onKeyDown={onKeyDown}
      ></AddButtonHeader>
    )
  }

  const amountFormatCell = (props: any) => (
    <InlineCellAmountCell
      props={props}
      enterEdit={enterEdit}
      onChange={itemChange}
      exitEdit={exitEdit}
      onKeyDown={onKeyDown}
      tabIndex={0}
    ></InlineCellAmountCell>
  )

  const textCell = (cellProps: GridCellProps) => (
    <InCellTextCell
      props={cellProps}
      enterEdit={enterEdit}
      onChangeGrid={itemChange}
      exitEdit={exitEdit}
      onKeyDown={onKeyDown}
      tabIndex={0}
    />
  )

  const NumericCell = (cellProps: GridCellProps) => (
    <InCellNumericCell
      props={cellProps}
      enterEdit={enterEdit}
      onChangeGrid={itemChange}
      exitEdit={exitEdit}
      onKeyDown={onKeyDown}
      tabIndex={0}
    />
  )

  const actionCell = (props: GridCellProps) => (
    <GridActionIconCell
      actions={[ActionOption.Delete]}
      gridCellProps={props}
      changeHandler={actionHandler}
    />
  )

  const costFooterCell = (props: any) => {
    if (!details) return <td style={footerCellStyle}>0</td>

    const data = (details || [])
      .filter((ops) => !ops.isDeleted)
      .map((item: any) => item.costPerUnit)
    const total = data.reduce((acc: any, value: any) => acc + value, 0)
    return <td style={footerCellStyle}>{CurrencyFormatter(total)}</td>
  }

  const totalFooterCell = (props: any) => {
    if (!details) return <td style={footerCellStyle}>0</td>

    const data = (details || [])
      .filter((ops) => !ops.isDeleted)
      .map((item: any) => item.totalCost)
    const total = data.reduce((acc: any, value: any) => acc + value, 0)
    return <td style={footerCellStyle}>{CurrencyFormatter(total)}</td>
  }

  const outsideGridRef = useOutsideClick(() => {
    const newData: any = [];
    
    details.forEach((item, index) => {
      newData.push({
        ...item,
        [EDIT_FIELD]: undefined,
        isEdit: false,
      })
    })

    sortDetails(newData);
  });

  const refreshSort = () => {
    const newData: any = [];
    
    details.forEach((item, index) => {
      newData.push({
        ...item
      })
    })

    sortDetails(newData);
  }

  const sortDetails = (newDetails: Array<QuoteDetail>) => {
    replaceDetails([...orderBy(newDetails, sort)])
  }

  return (
    <div id='quoteGrid' tabIndex={FIELD_QUOTE_TABINDEX.quoteGrid}>
      <Modal1
        show={isShowDeletedModal}
        title={`Delete Material in row ${selectedIndex + 1}.`}
        message={'You are deleting an Material, Continue?'}
        handleClose={() => setIsShowDeletedModal(false)}
        proceed={proceedHandler}
      />
      <Grid
        ref={outsideGridRef}
        className='mt-5'
        resizable={true}
        reorderable={true}
        data={(details || []).filter((ops) => !ops.isDeleted)}
        cellRender={customCellRender}
        rowRender={customRowRender}
        onItemChange={itemChange}
        editField={EDIT_FIELD}
        style={{height: '250px'}}
        onColumnResize={handleResizeColumn}
      >
        <Column
          field={autoColumns[FIELD_QUOTE_MAT_LIST.action].field}
          width={autoColumns[FIELD_QUOTE_MAT_LIST.action].width}
          cell={actionCell}
          editable={false}
          headerCell={addButtonHeader}
        />
        <Column
          title='Description'
          field='description'
          cell={textCell}
          footerCell={() => <td style={footerCellStyle}>Totals</td>}
        ></Column>
        <Column title='Drawing' field='drawing' cell={textCell}></Column>
        <Column title='Revision' field='revision' cell={textCell}></Column>
        <Column title='Qty' field='quantity' cell={NumericCell}></Column>
        <Column
          title='Cost Per Unit'
          field='costPerUnit'
          cell={amountFormatCell}
          footerCell={costFooterCell}
        ></Column>
        <Column
          title='Total Cost'
          field='totalCost'
          cell={amountFormatCell}
          footerCell={totalFooterCell}
        ></Column>
        <Column title='Estimated Lead Time' field='estLeadTime' cell={textCell}></Column>
      </Grid>
    </div>
  )
}

export {QuoteDetails}
