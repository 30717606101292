import React, {useContext, useEffect, useState} from 'react'
import {IQuote, ISelectedClientData, Material} from '../models/quote-model'
import {AddressNotification} from '../../../shared/components/notification/AddressNotification'
import {
  convertQuoteToJob,
  createCopyQuote,
  createNewQuote,
  createQuoteVersion,
  deleteQuote,
  getClientById,
  getQuoteByid,
  getQuoteByQuoteId,
  sendQuote,
  updateNewQuote,
  updatePrintDate,
} from '../api'
import {Controller, useFieldArray, useForm, useWatch} from 'react-hook-form'
import {NEW_QUOTE_FORM_DEFAULT} from '../constant/quote-default'
import {yupResolver} from '@hookform/resolvers/yup'
import {quoteFormValidationSchema} from '../validators/quote-form'
import {QuoteContext} from '../context/QuoteContext'
import {useAsyncFn, useEffectOnce, useLocation} from 'react-use'
import {ModuleContext} from '../../../shared/ModuleContext'
import {
  transformDataClient,
  transformDataVersions,
} from '../transformer/quote-transformer'
import {useHistory} from 'react-router-dom'
import {CustomAlert, IAlert} from '../../../shared/components/CustomAlert'
import Select from 'react-select'
import {DatePicker} from '@progress/kendo-react-dateinputs'
import {DeleteButton} from '../../../../_metronic/partials/content/button/action/DeleteButton'
import {QuoteDetails} from './partial/QuoteDetails'
import {QuoteDetail} from '../models/quote-detail'
import {INewEditData} from '../models/new-qoute-model'
import {NewPrintQuoteBtn} from './partial/NewPrintQuoteBtn'
import {NewSendQuoteBtn} from './partial/NewSendQuoteBtn'
import {IEmailSend} from '../../../shared/model/smtp-register'
import { usePageData } from '../../../../_metronic/layout/core'
import { MODULE_SCOPE, isHasAccess } from '../../../shared/service/user-role-utils'
import { FIELD_QUOTE_TABINDEX } from '../constant/config-map'
import { orderBy } from '@progress/kendo-data-query'

interface IProps {
  quoteData?: INewEditData
  isCanEdit: boolean
}

const NewQuoteForm: React.FC<IProps> = ({quoteData, isCanEdit}) => {
  const { currentUser } = usePageData()
  const history = useHistory()
  const {setContextToaster, clients, getAllClientsAsync} = useContext(QuoteContext)
  const {jobTypes, getAllJobTypesAsync} = useContext(ModuleContext)
  const [customAlert, setCustomAlert] = useState<IAlert | undefined>()
  const hasAccess = isHasAccess(currentUser.scopes, MODULE_SCOPE.QUOTESCONTROL)
  const [refresh, doRefresh] = useState(0);
  // **
  // Query Params
  // **
  const search = useLocation().search
  // **
  // Form Setup
  // **
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: quoteData || NEW_QUOTE_FORM_DEFAULT,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(quoteFormValidationSchema),
  })

  const {
    fields: details,
    insert: addDetails,
    remove: removeDetails,
    update: updateDetails,
    replace: replaceDetails,
  } = useFieldArray({
    control,
    name: 'details',
    keyName: 'keyIdx',
  })

  // **
  // Form Value
  // **
  const cliendIdValue = useWatch({
    control,
    name: 'clientId',
  })

  // **
  // Initial Load
  // **
  useEffectOnce(() => {
    getAllClientsAsync()
    getAllReferences()
  })
  const getAllReferences = async () => {
    if (jobTypes.length === 0) getAllJobTypesAsync()
  }

  const [copyBtn, setCopyBtn] = React.useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  useEffect(() => {
    if (quoteData) {
      if (quoteData.id == 0 || quoteData.status !== 'For Client Confirmation') {
        setCopyBtn(false)
      }
      if (quoteData.status == 'Completed') {
        setIsCompleted(true)
      }
      if (quoteData.id != 0) {
        quoteData.quoteNumber.includes('-') ? setCopyBtn(false) : setCopyBtn(true)
      }
      reset({
        ...quoteData,
      })
    } else {
      setCopyBtn(false)
      setIsCompleted(false)
      reset(NEW_QUOTE_FORM_DEFAULT)
    }
  }, [quoteData, reset, setContextToaster])

  const getQuoteByQuoteIdAsync = async (quoteId: string) => {
    const [data]: any = await getQuoteByQuoteId(quoteId)
    if (data) {
      reset(data.data)
    } else {
      history.push('/quote/list')
    }
  }

  const [{loading: fetchingDetails}, getQuoteById] = useAsyncFn(async (detail: any) => {
    if (quoteData) {
      const [data]: any = await getQuoteByid(quoteData.id)
      if (!data) return

      quoteData.details = data.data.details;

      quoteData.details.forEach((x: any, idx) => {
        if(detail && detail.index === idx) {
          x.isEdit = true;
          x.inEdit = detail.inEdit;
        }
      });

      setValue('details', quoteData.details)
    }
  })

  const getQuoteByIdAsync = async (quoteId: string) => {
    const [data]: any = await getQuoteByQuoteId(quoteId)
    if (data) {
      reset(data.data)
    } else {
      history.push('/quote/list')
    }
  }

  // **
  // Selected Client
  // **
  const [selectedClient, setSelectedClient] = useState<ISelectedClientData>()
  const setClientDetails = async (clientId: number) => {
    const [data]: any = await getClientById(clientId)
    if (!data) return

    if(data.data) {
      setSelectedClient(data.data);
    } else {
      setValue('clientId', 0);
    }
  }
  useEffect(() => {
    if (cliendIdValue) {
      setClientDetails(cliendIdValue)
    }
  }, [cliendIdValue, clients, setValue])

  const hasClient = () => {
    if (selectedClient) {
      if (
        selectedClient.postCode === null &&
        selectedClient.street === null &&
        selectedClient.state === null &&
        selectedClient.street === null
      ) {
        return false
      } else return true
    } else if (selectedClient === undefined) return true
    // must not display if undefined
    else return false
  }

  // **
  // Action Handler
  // **
  const onReset = () => {
    reset(NEW_QUOTE_FORM_DEFAULT)
  }
  const copyQuoteCard = () => {
    createCopyQuote(quoteData?.id || 0).then((response) => {
      history.push({
        pathname: '/quote/edit',
        search: `?id=${response.data}`,
      })
      window.location.reload()
    })
  }
  const versionQuoteCard = () => {
    createQuoteVersion(quoteData?.id || 0).then((response) => {
      history.push({
        pathname: '/quote/edit',
        search: `?id=${response.data}`,
      })
      window.location.reload()
    })
  }

  const onSubmit = async (values: INewEditData) => {
    doRefresh(prev => prev + 1);

    values.details = [...orderBy(values.details, [{field: 'sortIdx', dir: 'asc'}])]
    const editing = values.details.map((x: any, idx) => x.isEdit ? { inEdit: x.inEdit, index: idx } : null).find(x => x !== null);

    const payload: INewEditData = values
    setIsSaving(true)
    if (!values.id || values.id === 0) {
      createNewQuote(payload)
        .then((response: any) => {
          setIsSaving(false)
          history.push({
            pathname: '/quote/edit',
            search: `?id=${response}`,
          })
        })
        .catch((err) => {
          setCustomAlert({
            message: err.response.data ? err.response.data : 'Error saving',
            header: `Error saving Quote`,
            type: 'danger',
          })
          setIsSaving(false)
        })
    } else {
      updateNewQuote(payload)
        .then(() => {
          getQuoteById(editing)
          setCustomAlert({
            message: `Quote edited successfully.`,
            header: `Quote`,
            type: 'primary',
          })
          setIsSaving(false)
        })
        .catch((err) => {
          setCustomAlert({
            message: err.response.data ? err.response.data : 'Error saving',
            header: `Error saving Quote`,
            type: 'danger',
          })
          setIsSaving(false)
        })
    }
  }

  const [modalConfirmShow, setConfirmModalShow] = useState(false)
  const closeConfirmModal = () => {
    setConfirmModalShow(false)
  }
  const confirmCallback = () => {
    setConfirmModalShow(false)
    history.push('/quote/list')
  }

  const updateMaterialsHandler = (details: QuoteDetail[]) => {
    setValue('details', details)
  }

  const sendEmailHandler = (sendEmail: IEmailSend, quotesId: string[], email: string) => {
    setCustomAlert({
      message: `Please wait while sending email.`,
      header: `Sending the Quote.`,
      type: 'info',
    })
    sendQuote(sendEmail, quotesId, email)
      .then((res) => {
        setCustomAlert({
          message: `Quote Sent Successfully`,
          header: `Quote Sent.`,
          type: 'primary',
        })
      })
      .catch((err) => {
        let msg = err.response.data
        if (msg && msg.includes('Authentication unsuccessful')) {
          msg =
            'Invalid sender credentials. Please ensure that the provided sender information is valid.'
        } else msg = `Server Error. Please try again later.`

        setCustomAlert({
          message: msg,
          header: `Quote Not Sent.`,
          type: 'danger',
        })
      })
  }

  return (
    <React.Fragment>
      {!hasClient() && <AddressNotification></AddressNotification>}
      {customAlert && <CustomAlert {...customAlert} />}
      <form onSubmit={handleSubmit(onSubmit)} onReset={onReset} name='quote'>
        {/* Action Buttons */}
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-outline-primary me-5'
            tabIndex={FIELD_QUOTE_TABINDEX.btnBack}
            onClick={() => {
              history.push('/quote/list')
            }}
          >
            Back
          </button>

          {(quoteData?.id || 0) > 0 && (
            <React.Fragment>
              <NewPrintQuoteBtn
                versions={quoteData?.quoteVersions}
                isDisable={!hasAccess}
                currentQuote={getValues('quoteNumber') || ''}
                donePrint={() => {
                  updatePrintDate(+(getValues('id') || 0)).then((response) => {
                    setValue('datePrinted', new Date())
                  })
                }}
              />

              <NewSendQuoteBtn
                versions={quoteData?.quoteVersions}
                currentQuote={getValues('quoteNumber') || ''}
                donePrint={() => {
                  window.location.reload()
                }}
                sendQuoteHandler={sendEmailHandler}
                isDisable={!hasAccess}
              />
              <button
                type='button'
                className='btn btn-outline-primary col-auto me-5'
                disabled={!hasAccess}
                tabIndex={FIELD_QUOTE_TABINDEX.btnConvertToJob}
                onClick={() => {
                  convertQuoteToJob(quoteData?.id || 0).then((result) => {
                    setCustomAlert({
                      message: `Convert Quote to Job successfully. Job No.: ${result.data}`,
                      header: `Convert Quote to job`,
                      type: 'primary',
                    })
                  })
                }}
              >
                Convert To Job
              </button>
              {/* {getValues('status') === 'Completed' && (
                <ConvertQuoteToJobBtn quoteData={getValues()} />
              )} */}
            </React.Fragment>
          )}
          {(quoteData?.id || 0) > 0 && (
            <React.Fragment>
              <button
                type='button'
                className='btn btn-primary col-auto me-5'
                onClick={versionQuoteCard}
                disabled={isCompleted || !hasAccess}
                tabIndex={FIELD_QUOTE_TABINDEX.btnCreateVersion}
              >
                Create Version
              </button>
              {/* {copyBtn && ( */}
              <button
                type='button'
                className='btn btn-primary col-auto me-5'
                onClick={copyQuoteCard}
                disabled={!hasAccess}
                tabIndex={FIELD_QUOTE_TABINDEX.btnCopyQuote}
              >
                Copy Quote
              </button>
              {/* )} */}

              <DeleteButton
                title={`Delete Quote ${quoteData?.number}`}
                modalMessage={'You are deleting a Quote, Continue?'}
                className={'me-5'}
                disabled={isCompleted || !hasAccess}
                tabIndex={FIELD_QUOTE_TABINDEX.btnDelete}
                deleteHandler={() => {
                  if (quoteData) {
                    deleteQuote(quoteData).then(() => {
                      history.push('/quote/list')
                    })
                  }
                }}
              ></DeleteButton>
            </React.Fragment>
          )}

          <button
            type='submit'
            className='btn btn-primary col-auto'
            disabled={isSaving || isCompleted || !hasAccess}
            tabIndex={FIELD_QUOTE_TABINDEX.btnSave}
          >
            Save
          </button>
        </div>
        {/* Form Fields */}
        <h5 className='mb-2 mt-5'>Quote Information</h5>
        <div className='row mt-6 pe-2'>
          <div className='col-12 col-lg-3 col-md-3'>
            <table className='form-table' style={{width: '100%'}}>
              <tbody>
                <tr>
                  <th style={{width: '100px'}}></th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <label className='form-label' htmlFor='quoteNumber'>
                      Quote No./Version
                    </label>
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name='quoteNumber'
                      render={({field: {value, name, onChange}}) => {
                        return (
                          <Select
                            options={transformDataVersions(quoteData?.quoteVersions || [])}
                            onChange={(event: any) => {
                              onChange(event.value)
                              getQuoteByQuoteIdAsync(event.value)
                            }}
                            className={`controllerSelect${
                              errors.quoteNumber ? ' border-danger' : ''
                            }`}
                            name={name}
                            value={transformDataVersions(quoteData?.quoteVersions || []).find(
                              (cl: any) => cl.value === value
                            )}
                            autoFocus
                            isSearchable={false}
                            tabIndex={FIELD_QUOTE_TABINDEX.quoteNumber}
                            isDisabled={!hasAccess}
                          ></Select>
                        )
                      }}
                    />
                    {/* <input
                      type='text'
                      className={`form-control${errors.quoteNumber ? ' border-danger' : ''}`}
                      {...register(`quoteNumber`)}
                      disabled
                      tabIndex={1}
                    /> */}
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className='form-label' htmlFor='client'>
                      Client Name
                    </label>
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name='clientId'
                      render={({field: {value, name, onChange}}) => {
                        return (
                          <Select
                            options={transformDataClient(clients)}
                            onChange={(event: any) => onChange(event.value)}
                            className={`controllerSelect${errors.clientId ? ' border-danger' : ''}`}
                            name={name}
                            value={transformDataClient(clients).find(
                              (cl: any) => cl.value === value
                            )}
                            autoFocus
                            tabIndex={FIELD_QUOTE_TABINDEX.clientName}
                            isDisabled={isCompleted || !hasAccess}
                          ></Select>
                        )
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-12 col-lg-3 col-md-3'>
            <table className='form-table' style={{width: '100%'}}>
              <tbody>
                <tr>
                  <th style={{width: '100px'}}></th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <label className='form-label' htmlFor='quoteNumber'>
                      No. of Versions
                    </label>
                  </td>
                  <td>
                    <div>{quoteData?.totalVersion}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className='form-label' htmlFor='client'>
                      Contact Name
                    </label>
                  </td>
                  <td>
                    <input
                      type='text'
                      className={`form-control${errors.quoteNumber ? ' border-danger' : ''}`}
                      {...register(`contactName`)}
                      disabled={!hasAccess}
                      tabIndex={FIELD_QUOTE_TABINDEX.contactName}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-12 col-lg-3 col-md-3'>
            <table className='form-table' style={{width: '100%'}}>
              <tbody>
                <tr>
                  <th style={{width: '100px'}}></th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <label className='form-label' htmlFor='quoteNumber'>
                      Quote Date
                    </label>
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name='created'
                      render={({field: {value, name, onChange}}) => {
                        return (
                          <DatePicker
                            format='dd/MM/yyyy'
                            name={name}
                            onChange={onChange}
                            value={value ? new Date(value || '') : null}
                            tabIndex={9}
                            disabled
                          />
                        )
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className='form-label' htmlFor='client'>
                      Print/Email Date
                    </label>
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name='datePrinted'
                      render={({field: {value, name, onChange}}) => {
                        return (
                          <DatePicker
                            format='dd/MM/yyyy'
                            name={name}
                            onChange={onChange}
                            value={value ? new Date(value || '') : null}
                            tabIndex={9}
                            disabled
                          />
                        )
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-12 col-lg-3 col-md-3'>
            <table className='form-table' style={{width: '100%'}}>
              <tbody>
                <tr>
                  <th style={{width: '100px'}}></th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <label className='form-label' htmlFor='quoteNumber'>
                      Quote By
                    </label>
                  </td>
                  <td>
                    <input
                      type='text'
                      className={`form-control${errors.quoteNumber ? ' border-danger' : ''}`}
                      {...register(`createdBy`)}
                      disabled
                      tabIndex={1}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <table className='form-table' style={{width: '100%'}}>
              <tbody>
                <tr>
                  <th style={{width: '100px'}}></th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <label className='form-label' htmlFor='quoteNumber'>
                      Quote Description
                    </label>
                  </td>
                  <td>
                    <textarea
                      className={`form-control${errors.quoteNumber ? ' border-danger' : ''}`}
                      {...register(`description`)}
                      disabled={isCompleted || !hasAccess}
                      tabIndex={FIELD_QUOTE_TABINDEX.description}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Quote Details */}
        <div className='row mt-3'>
          <div className='col'>
            <h5>Quote Details</h5>
            <QuoteDetails
              refresh={refresh}
              isCanEdit={isCanEdit && !isCompleted && hasAccess}
              // details={details}
              // addDetail={(newDetails) => addDetails(details.length, {...newDetails})}
              // updateDetail={(idx, material) => {
              //   let newDetails = getValues('details')
              //   newDetails[idx] = material
              //   updateMaterialsHandler(newDetails)
              // }}
              // replaceDetails={(newMaterials: QuoteDetail[]) => replaceDetails(newMaterials)}
              // removeDetail={removeDetails}
              // updateDetails={updateMaterialsHandler}
              control={control}
            ></QuoteDetails>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col'>
            <h5>Notes</h5>
            <textarea
              className={`form-control${errors.notes ? ' border-danger' : ''}`}
              {...register(`notes`)}
              tabIndex={FIELD_QUOTE_TABINDEX.notes}
              disabled={!hasAccess}
            />
          </div>
          <div className='col'>
            <h5>Payment Terms</h5>
            <div className='row'>
              <div className='col d-flex justify-content-between'>
                <span className='ms-4'>
                  <label className='form-label' htmlFor='setupText'>
                    30 days from invoice
                  </label>
                </span>
                <Controller
                  control={control}
                  name='is30DaysFromInv'
                  render={({field: {value}}) => {
                    return (
                      <input
                        type='radio'
                        name='paymentTerm'
                        className='default-check-input me-4'
                        tabIndex={FIELD_QUOTE_TABINDEX.chk30daysInvice}
                        checked={value}
                        disabled={!hasAccess}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setValue('progressPaymentRequired', false)
                            setValue('isCod', false)
                            setValue('isDepositReceivedCOD', false)
                          }
                          setValue('is30DaysFromInv', event.target.checked)
                        }}
                      />
                    )
                  }}
                />

                {/* <input
                  type='checkbox'
                  className='me-4'
                  tabIndex={16}
                  {...register(`is30DaysFromInv`, {
                    onChange: (event) => {},
                  })}
                /> */}
              </div>
              <div className='col d-flex justify-content-between'>
                <span className='ms-4'>
                  <label className='form-label' htmlFor='isCod'>
                    50% deposit required with final payment due COD
                  </label>
                </span>
                <Controller
                  control={control}
                  name='isDepositReceivedCOD'
                  render={({field: {value}}) => {
                    return (
                      <input
                        type='radio'
                        name='paymentTerm'
                        className='default-check-input me-4'
                        tabIndex={FIELD_QUOTE_TABINDEX.chk50Deposit}
                        checked={value}
                        disabled={!hasAccess}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setValue('progressPaymentRequired', false)
                            setValue('isCod', false)
                            setValue('is30DaysFromInv', false)
                          }
                          setValue('isDepositReceivedCOD', event.target.checked)
                        }}
                      />
                    )
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex justify-content-between'>
                <span className='ms-4'>
                  <label className='form-label' htmlFor='setupText'>
                    COD
                  </label>
                </span>
                <Controller
                  control={control}
                  name='isCod'
                  render={({field: {value}}) => {
                    return (
                      <input
                        type='radio'
                        name='paymentTerm'
                        className='default-check-input me-4'
                        tabIndex={FIELD_QUOTE_TABINDEX.chkCOD}
                        checked={value}
                        disabled={!hasAccess}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setValue('progressPaymentRequired', false)
                            setValue('isDepositReceivedCOD', false)
                            setValue('is30DaysFromInv', false)
                          }
                          setValue('isCod', event.target.checked)
                        }}
                      />
                    )
                  }}
                />
              </div>
              <div className='col d-flex justify-content-between'>
                <span className='ms-4'>
                  <label className='form-label' htmlFor='setupText'>
                    Progress payments required
                  </label>
                </span>
                <Controller
                  control={control}
                  name='progressPaymentRequired'
                  render={({field: {value}}) => {
                    return (
                      <input
                        type='radio'
                        name='paymentTerm'
                        className='default-check-input me-4'
                        tabIndex={FIELD_QUOTE_TABINDEX.chkProgressPay}
                        checked={value}
                        disabled={!hasAccess}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setValue('isCod', false)
                            setValue('isDepositReceivedCOD', false)
                            setValue('is30DaysFromInv', false)
                          }
                          setValue('progressPaymentRequired', event.target.checked)
                        }}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export {NewQuoteForm}
