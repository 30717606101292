import {IEmailSend} from '../../../../../app/shared/model/smtp-register'
import {ANDREW_SIGNATURE_MESSAGE} from '../../../../../app/shared/service/utils'

export const emailSendDefault: IEmailSend = {
  currentContent: ANDREW_SIGNATURE_MESSAGE,
  emails: [],
  subjects: '',
  emailCcs: [],
  emailBccs: [],
  attachments: [],
  fileName: '',
}
