export enum MODULE_SCOPE {
    QUOTES = 'quotes',
    QUOTESCONTROL = 'quotes.control',
    JOBS = 'jobs',
    JOBS_SALESINFORMATION = 'jobs.salesinformation',
    JOBS_INFORMATION = 'jobs.information',
    JOBS_OPERATIONS = 'jobs.operations',
    JOBS_PURCHASEORDERS = 'jobs.purchase.orders',
    PURCHASEORDERS = 'purchaseorders',
    PURCHASEORDERSCONTROL = 'purchaseorders.control',
    DELIVERIES = 'deliveries',
    DELIVERIESCONTROL = 'deliveries.control',
    STOCK = 'stock',
    STOCKCONTROL = 'stock.control',
    CLIENTS = 'clients',
    CLIENTSCONTROL = 'clients.control',
    SCHEDULES = 'schedules',
    SCHEDULESCONTROL = 'schedules.control',
    SUPPLIERS = 'suppliers',
    SUPPLIERS_INFORMATION = 'suppliers.information',
    SUPPLIERS_APPROVAL = 'suppliers.approval',
    NONCONFORMANCE = 'nonconformance',
    NONCONFORMANCECONTROL = 'non-conformance.control',
    SETTINGS = 'settings',
    SETTINGSCONTROL = 'settings.control',
}

export const isHasAccess = (scopes: string[], allowedModule: string): boolean => {
    return scopes.some((scope) => scope === allowedModule)
}

