// import { deleteQuote } from '../api'
import React, {useState} from 'react'
import {PrintQuoteOptionModal} from '../modal/PrintQuoteOptionModal'
import {usePageData} from '../../../../../_metronic/layout/core'
import {IEmailSend} from '../../../../shared/model/smtp-register'
import {emailSendDefault} from '../../../../../_metronic/partials/modals/send-email/defaults/email-send-default'
import {SendQuoteModal} from '../../../../../_metronic/partials/modals/send-email/quote/send-quote'
import {sendQuote} from '../../api'
import {RegisterSmtp} from '../../../purchase-orders/components/modal/RegisterSmtp'
import {registerEmailAuth, validateExistsEmailAuth} from '../../../../shared/api/email'
import {AttachementRefBuilder} from './AttachmentRefBuilder'
import { FIELD_QUOTE_TABINDEX } from '../../constant/config-map'

interface IProps {
  currentQuote: string
  versions?: string[]
  donePrint: () => void
  sendQuoteHandler: (sendEmail: IEmailSend, quotesId: string[], email: string) => void
  isDisable: boolean
}

const NewSendQuoteBtn: React.FC<IProps> = ({currentQuote, versions, sendQuoteHandler, isDisable}) => {
  const [modalPrintShow, setPrintModalShow] = useState(false)
  const [printedData, setPrintedData] = useState<any>([])
  const [isSummary, setIsSummary] = useState(false)
  const [isShowSmtpModal, setIsShowSmtpModal] = useState<boolean>(false)
  const [existSmtp, setExistSmtp] = useState<boolean>(false)

  const [showSendModal, setShowSendModal] = useState<boolean>(false)
  const [emailSend, setEmailSend] = useState<IEmailSend>({
    ...emailSendDefault,
    subjects: 'Send Quote',
  })
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false)
  const {currentUser} = usePageData()

  const [isPreparingAttch, setIsPreparingAttch] = useState<boolean>(false)
  const [currentNumber, setCurrentNumber] = useState<number>(0)

  const printQuoteHandler = (data: any[], isSummary: boolean) => {
    setPrintedData(data)
    setIsSummary(isSummary)
    setShowSendModal(true)
    setEmailSend({
      ...emailSendDefault,
      subjects: 'Send Quote',
    })
  }

  const validateUserAuth = async () => {
    const [data]: any = await validateExistsEmailAuth(currentUser.email)
    if (data) {
      setPrintModalShow(true)
      setExistSmtp(data.data)
    } else {
      setExistSmtp(false)
    }
  }

  const sendEmailHandler = (sendEmail: IEmailSend) => {
    setIsSendingEmail(true)
    sendQuoteHandler(
      sendEmail,
      printedData.map((x: any) => x.quoteId),
      currentUser.email
    )
    setShowSendModal(false)
    setIsSendingEmail(false)
  }

  return (
    <React.Fragment>
      {isShowSmtpModal && (
        <RegisterSmtp
          smtpRegister={{
            email: currentUser.email,
            password: '',
          }}
          toggleDialog={() => {
            setIsShowSmtpModal(false)
          }}
          savePasswordHandler={(smtpRegister) => {
            registerEmailAuth(smtpRegister.email, smtpRegister.password).then(() => {
              setExistSmtp(true)
              setShowSendModal(true)
              setIsShowSmtpModal(false)
            })
          }}
        ></RegisterSmtp>
      )}
      {showSendModal && (
        <SendQuoteModal
          onClose={() => {
            setShowSendModal(false)
            setCurrentNumber(0)
            setIsPreparingAttch(false)
            setPrintedData([])
          }}
          onSend={sendEmailHandler}
          errorMsg={''}
          dataEmailSend={emailSend}
          changeEmailSend={(resEmailSend: IEmailSend) => {
            setEmailSend(resEmailSend)
          }}
          proceedMsg={
            isPreparingAttch
              ? `Preparing Attachment ${currentNumber}/${printedData.length}`
              : 'Proceed'
          }
          disabled={isPreparingAttch}
        />
      )}
      {printedData && printedData.length > 0 && (
        <AttachementRefBuilder
          isSummary={isSummary}
          printedData={printedData}
          attachmentHandler={(attchment) => {
            setEmailSend({
              ...emailSend,
              attachments: attchment,
            })
          }}
          currentPreparingAttchment={(currnt: number) => {
            setIsPreparingAttch(true)
            setCurrentNumber(currnt + 1)
            if (currnt + 1 == printedData.length) {
              setTimeout(() => {
                setIsPreparingAttch(false)
              }, 5000)
              setPrintModalShow(false)
            }
          }}
        ></AttachementRefBuilder>
      )}
      <PrintQuoteOptionModal
        handleClose={() => {
          setPrintModalShow(false)
        }}
        show={modalPrintShow}
        versions={versions}
        currentQuote={currentQuote}
        printQuote={printQuoteHandler}
        buttonText={'Send'}
        disabled={isSendingEmail && isPreparingAttch && printedData.length != emailSend.attachments}
      ></PrintQuoteOptionModal>

      <button
        type='button'
        className='btn btn-primary col-auto me-5'
        disabled={isSendingEmail || isDisable}
        tabIndex={FIELD_QUOTE_TABINDEX.btnSendQuote}
        onClick={() => {
          if (!existSmtp) validateUserAuth()
          else setPrintModalShow(true)
        }}
      >
        Send Quote
      </button>
    </React.Fragment>
  )
}

export {NewSendQuoteBtn}
