/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {LandingLayout} from '../../../_metronic/layout/LandingLayout'
import {Link, useHistory} from 'react-router-dom'
import {UserModel} from '../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/RootReducer'
import {
  IUserAccessModule,
  PageDataProvider,
  usePageData,
} from '../../../_metronic/layout/core/PageData'
import { PageControlProvider } from '../../../_metronic/layout/core/PageControlProvider'
import { MODULE_SCOPE } from '../../shared/service/user-role-utils'

const DashboardPage: FC<{userAccessModule: IUserAccessModule}> = ({userAccessModule}) => {
  return (
    <>
      {/* begin::Row */}
      <h1 className='landing-title'>Dashboard</h1>
      <div className='card card-custom mt-5'>
        <div className='card-body'>
          <div className="d-flex justify-content-center flex-wrap ">
            <PageControlProvider allowedAccess={[MODULE_SCOPE.QUOTES]}>
              <div className='ps-5 pe-5 text-center items'>
                  <Link to='/quote'>
                    <i className='fas fa-fw fa-clipboard-list'></i>
                    <span>Quotes</span>
                  </Link>
              </div>
            </PageControlProvider>
            <PageControlProvider allowedAccess={[MODULE_SCOPE.JOBS]}>
              <div className='ps-5 pe-5 text-center items'>
                <Link to='job'>
                  <i className='fas fa-fw fa-tasks'></i>
                  <span>Jobs</span>
                </Link>
              </div>
            </PageControlProvider>
            <PageControlProvider allowedAccess={[MODULE_SCOPE.PURCHASEORDERS]}>
              <div className='ps-5 pe-5 text-center items'>
                <Link to='purchase-order'>
                    <i className='fas fa-fw fa-file-invoice-dollar'></i>
                    <span>Purchase Orders</span>
                </Link>
              </div>
            </PageControlProvider>
            <PageControlProvider allowedAccess={[MODULE_SCOPE.DELIVERIES]}>
              <div className='ps-5 pe-5 text-center items' >
                <Link to='delivery'>
                    <i className='fas fa-fw fa-truck'></i>
                    <span>Deliveries</span>
                  </Link>
              </div>
            </PageControlProvider>
            <PageControlProvider allowedAccess={[MODULE_SCOPE.STOCK]}>
            <div className='ps-5 pe-5 text-center items'>
                <Link to='stock'>
                  <i className='fas fa-fw fa-pallet'></i>
                  <span>Stocks</span>
                </Link>
            </div>
            </PageControlProvider>
            <PageControlProvider allowedAccess={[MODULE_SCOPE.CLIENTS]}>
              <div className='ps-5 pe-5 text-center items'>
                  <Link to='/client'>
                      <i className='fas fa-fw fa-users'></i>
                      <span>Clients</span>
                  </Link>
              </div>
            </PageControlProvider>
           
            <PageControlProvider allowedAccess={[MODULE_SCOPE.SCHEDULES]}>
              <div className='ps-5 pe-5 text-center items'>
                <Link to='/schedule'>
                    <i className='fas fa-fw fa-calendar-alt'></i>
                    <span>Schedules</span>
                  </Link>
              </div>
            </PageControlProvider>
            <PageControlProvider allowedAccess={[MODULE_SCOPE.SUPPLIERS]}>
              <div className='ps-5 pe-5 text-center items'>
                  <Link to='/supplier'>
                    <i className='fas fa-fw fa-store'></i>
                    <span>Suppliers</span>
                  </Link>
              </div>
            </PageControlProvider>
            <PageControlProvider allowedAccess={[MODULE_SCOPE.NONCONFORMANCE]}>
              <div className='ps-5 pe-5 text-center items'>
                  <Link to='non-conformance'>
                    <i className='fas fa-fw fa-assistive-listening-systems'></i>
                    <span>Non-Conformance</span>
                  </Link>
              </div>
            </PageControlProvider>
            <PageControlProvider allowedAccess={[MODULE_SCOPE.SETTINGS]}>
              <div className='ps-5 pe-5 text-center items'>
                  <Link to='/settings'>
                    <i className='fas fa-fw fa-cog'></i>
                    <span>Settings</span>
                  </Link>
              </div>
            </PageControlProvider>
          </div>
        </div>
      </div>

      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageDataProvider>
        <DashboardContent />
      </PageDataProvider>
    </>
  )
}

const DashboardContent: FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const {userAccessModule, currentUser} = usePageData()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  if (user.isResetPasswordRequired) {
    history.push('/change-password')
  }

  return (
    <>
      <LandingLayout>
        <DashboardPage userAccessModule={userAccessModule} />
      </LandingLayout>
    </>
  )
}

export {DashboardWrapper}
