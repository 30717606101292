import React, {useEffect, useState} from 'react'
import {createRef, useRef} from 'react'
import NewQuoteReportPrintDetails from '../print/NewQuoteReportPrintDetails'
import {SendFileAttachment} from './SendFileAttachment'
import {IEmailSend} from '../../../../shared/model/smtp-register'
import {IFile} from '../../../../shared/model/file'
import jsPDF from 'jspdf'
import {useReactToPrint} from 'react-to-print'

interface IProps {
  printedData: any[]
  isSummary: boolean
  attachmentHandler: (attachments: IFile[]) => void
  currentPreparingAttchment: (prepare: number) => void
}

const AttachementRefBuilder = (props: IProps) => {
  let elementsRef = useRef(props.printedData.map(() => createRef())) as any
  const [attachments, setAttachments] = useState<IFile[]>([])
  const [isDone, setIsDone] = useState<boolean>(false)
  const [indexPrint, setIndexPrint] = useState<number>(-1)
  const [attachmentComplete, setAttachmentComplete] = useState<boolean>(false)

  useEffect(() => {
    if (!elementsRef.current.find((x: any) => x.current == null)) {
      setIsDone(true)
    }
  }, [elementsRef])

  useEffect(() => {
    if (attachments && attachments.length === props.printedData.length && !attachmentComplete) {
      props.attachmentHandler(attachments)
      setAttachmentComplete(true)
    }
  }, [attachments])

  useEffect(() => {
    if (isDone) {
      setIndexPrint(0)
    }
  }, [isDone])

  useEffect(() => {
    if (indexPrint >= 0 && elementsRef.current[indexPrint]) {
      printHandler()
      props.currentPreparingAttchment(indexPrint)
    }
  }, [indexPrint])

  const printHandler = useReactToPrint({
    content: () => elementsRef.current[indexPrint].current,
    pageStyle: ` .root {
              margin: 0;
              padding: 0
            }
        
            @media all {
              .page-break {
                display: none;
              }
            }
        
            @media print {
              html,
              body {
                height: initial !important;
                overflow: initial !important;
                background-color: #ffffff !important;
                -webkit-print-color-adjust: exact;
                table {
                  border: solid red !important;
                  border-width: 1px 0 0 1px !important;
                }
                th, td {
                    border: solid red !important;
                    border-width: 0 1px 1px 0 !important;
                }
              }
              @page :footer {
                display: none
              }
            
              @page :header {
                  display: none
              }
            }
        
            @media print {
              .page-break {
                margin-top: 20px;
                display: block;
                page-break-after: always;
              }
              .page-notes {
                bottom: 0px;
                width: 100%;
                height: 50px;
                font-size: 15px;
              }
              .page-footer {
                position: fixed;
                z-index: 9;
                bottom: 0;
                width: 100%;
                height: 50px;
                font-size: 15px;
                opacity: 0.5;
                page-break-after: always;
              }
            }`,
    print: async (pdfDocument: HTMLIFrameElement) => {
      const document = pdfDocument.contentDocument
      if (document) {
        const html = document.getElementsByTagName('html')[0]
        const doc = new jsPDF('p', 'pt', 'a4')
        doc.setFont('Helvetica')
        const options = {
          callback: function () {
            const blobUri = doc.output('blob')
            const file = new File([blobUri], `${props.printedData[indexPrint].quoteNumber}.pdf`)
            setAttachments([
              ...attachments,
              {
                title: `${props.printedData[indexPrint].quoteNumber}.pdf`,
                file: file,
              },
            ])
            setIndexPrint(indexPrint + 1)
          },
          width: 820,
          windowWidth: 800,
          html2canvas: {scale: 0.74},
        }
        doc.html(html, options)
      }
    },
  })

  return (
    <React.Fragment>
      <div style={{display: 'none'}}>
        {/* {isDone
          ? elementsRef.current.map((componentRef: any, i: number) => (
              <React.Fragment>
                {componentRef.current && (
                  <SendFileAttachment
                    key={i}
                    componentRef={componentRef}
                    quoteNumber={props.printedData[i].quoteNumber}
                    setAttachment={(attachemnt) => {
                      console.log(attachemnt)
                      if (!attachments.find((x) => x.file == attachemnt.file)) {
                        setAttachments([...attachments, attachemnt])
                      }
                    }}
                  ></SendFileAttachment>
                )}
              </React.Fragment>
            ))
          :  */}
        {elementsRef.current.map((componentRef: any, i: number) => (
          <React.Fragment>
            <NewQuoteReportPrintDetails
              refs={componentRef}
              dataItems={[props.printedData[i]]}
              isSummary={props.isSummary}
            />
          </React.Fragment>
        ))}

        <button type='button'></button>
      </div>
    </React.Fragment>
  )
}
export {AttachementRefBuilder}
